<template>
    <div class="card">
        <div
            class="card__image"
            :style="`background-image: url(${image})`" />
        <div class="card__info">
            <div class="card__icons">
                <div class="card__social">
                    <img
                        v-if="platforms.includes('facebook')"
                        :src="require('@/assets/icons/facebook.svg')"
                        alt="facebook-icon">
                    <img
                        v-if="platforms.includes('pinterest')"
                        :src="require('@/assets/icons/pinterest.svg')"
                        alt="pinterest-icon">
                    <img
                        v-if="platforms.includes('snapchat')"
                        :src="require('@/assets/icons/snapchat.svg')"
                        alt="snapchat-icon">
                    <img
                        v-if="platforms.includes('tiktok')"
                        :src="require('@/assets/icons/tiktok.svg')"
                        alt="tiktok-icon">
                    <img
                        v-if="platforms.includes('microsoft')"
                        :src="require('@/assets/icons/microsoft.svg')"
                        alt="microsoft-icon">
                </div>
            </div>
            <div class="card__text">
                <div class="card__title">
                    {{ name }}
                </div>
                <div class="card__desc">
                    Integer posuere erat a ante venenatis dapibus posuere velit aliquet.
                    Maecenas sed diam eget.
                </div>
                <div class="card__belongto">
                    <span class="card__author">Author:</span>
                    <span class="card__name">{{ author }}</span>
                </div>
                <div class="card__dates">
                    <div class="card__left">
                        <span class="card__created">Created:</span>
                        <span class="card__date">{{ created }}</span>
                    </div>
                    <div class="card__right">
                        <span class="card__created">Expires:</span>
                        <span class="card__date">{{ expires }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreativeAdCard',
    props: {
        image: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        platforms: {
            type: Array,
            required: true
        },
        author: {
            type: String,
            required: true
        },
        created: {
            type: String,
            required: true
        },
        expires: {
            type: String,
            required: true
        },
    },
};
</script>

<style lang="scss" scoped>
.card{
    width: calc(33% - 64px);
    display: inline-block;

    vertical-align: top;
    margin:45px 32px;
}
.card__image{
    padding-bottom: 93.7%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}
.card__icons{
    border-bottom: 1px solid #D9DADA;
}
.card__social{
    padding: 17px;
    display: inline-block;
    position: relative;
    img{
        max-width: 15px;
        max-height: 15px;
        margin: 0 3px;
    }
    &:after{
        content: '';
        position: absolute;
        right: 0;
        top:8px;
        width: 1px;
        height: 36px;
        background: #D9DADA;
    }
}
.card__info{
    border: 1px solid #D9DADA;
    border-top:0;
    background: white;
}
.card__text{
    padding: 17px 17px 12px 17px;
}
.card__title{
    font-size: 15px;
    font-weight: 600;
    color: #00A2EA;
    margin-bottom: 17px;
}
.card__desc{
    font-size: 12px;
    font-weight: 600;
    color: #8F9EA6;
    margin-bottom: 32px;
}
.card__author{
    font-size: 13px;
    color: #4A4A4A;
    margin-right: 5px;
    margin-left: 5px;
}
.card__name{
    font-size: 13px;
    color: #00A2EA;
    text-decoration: underline;
}
.card__dates{
    display:flex;
    justify-content: space-between;
    margin-top:15px;
}
.card__created{
    @extend .card__author;
    margin-left:0;
}
.card__date{
    font-size: 13px;
    color: #00A2EA;
}
</style>
