<template>
    <div>
        <div class="wrapper">
            <!-- filtration -->
            <div class="filtration">
                <div class="filtration__inner">
                    <div class="filtration__block">
                        <div class="filtration__title">
                            Platform
                        </div>
                        <ul class="filtration__list">
                            <li @click="addToFilter('facebook'); toggleClass($event)">
                                Facebook
                            </li>
                            <li @click="addToFilter('microsoft'); toggleClass($event)">
                                Microsoft
                            </li>
                            <li @click="addToFilter('snapchat'); toggleClass($event)">
                                Snapchat
                            </li>
                            <li @click="addToFilter('tiktok'); toggleClass($event)">
                                Tiktok
                            </li>
                            <li @click="addToFilter('pinterest'); toggleClass($event)">
                                Pinterest
                            </li>
                            <!--<div class="filtration__see">See All</div>-->
                        </ul>
                    </div>
                </div>
            </div>
            <!-- end filtration -->
            <div class="main">
                <!-- header of main block -->
                <div class="top">
                    <div class="top__selection">
                        <input
                            id="creative_ads"
                            v-model="topSelection"
                            type="radio"
                            name="top__selection"
                            value="creative_ads"
                            :class="{ selected: (topSelection === 'creative_ads') }">
                        <label
                            id="creative_ads_label"
                            for="creative_ads">Creative Ads</label>

                        <input
                            id="playbooks"
                            v-model="topSelection"
                            type="radio"
                            name="top__selection"
                            value="playbooks"
                            :class="{ selected: (topSelection === 'playbooks') }">
                        <label
                            id="playbooks_label"
                            for="playbooks">Playbooks</label>
                    </div>
                    <div class="top__line">
                        <div class="top__left">
                            <ul class="top__list">
                                <li @click="addToFilter('personal'); toggleClass($event)">
                                    My Ads
                                </li>
                                <li @click="addToFilter('agency'); toggleClass($event)">
                                    {{ currentAgency.name }} Ads
                                </li>
                                <li @click="addToFilter('marketplace'); toggleClass($event)">
                                    Marketplace Ads
                                </li>
                            </ul>
                        </div>
                        <div class="top__right">
                            <div class="top__perpage">
                                <div class="top__title">
                                    Items per page:
                                </div>
                                <div class="select-wrapper">
                                    <select
                                        id="perpage"
                                        v-model="selectedNumber"
                                        name="perpage"
                                        class="select"
                                        @change="changeNumber(selectedNumber)">
                                        <option
                                            checked
                                            value="3">
                                            3
                                        </option>
                                        <option value="6">
                                            6
                                        </option>
                                        <option value="12">
                                            12
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="top__count">
                                <span>{{ startIndex + 1 }}-{{ checkEnding }}</span> of <span>{{ selectedCards.length }}</span>
                            </div>
                            <div class="select-wrapper">
                                <select
                                    id="sortby"
                                    v-model="sortType"
                                    name="sortyby"
                                    class="select top__sortby"
                                    @change="sortBy(sortType)">
                                    <option
                                        disabled
                                        selected
                                        value="">
                                        Sort By
                                    </option>
                                    <option value="ASC">
                                        Ascending
                                    </option>
                                    <option value="DESC">
                                        Descending
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end header of main block -->

                <div class="cards">
                    <div class="subhead">
                        <div class="subhead__searchwrap">
                            <input
                                v-model="filter"
                                type="text"
                                class="subhead__search"
                                placeholder="Search">
                        </div>
                        <div class="subhead__create">
                            <a href="#">Create your own ADS</a>
                        </div>
                    </div>
                    <div class="card-wrapper">
                        <div v-if="topSelection === 'creative_ads'">
                            <creative-ad-card
                                v-for="(item, index) in paginatedCards"
                                :key="index"
                                :image="item.image"
                                :name="item.name"
                                :platforms="item.platforms"
                                :created="item.created"
                                :expires="item.expires"
                                :author="item.author" />
                        </div>
                        <div
                            v-if="noMatch"
                            class="nomatch">
                            <h1>Sorry, nothing found... please change your filters</h1>
                        </div>
                        <div
                            v-if="topSelection === 'playbooks'"
                            class="nomatch">
                            <h1>Playbooks is under development, please take a look at Creative Ads</h1>
                        </div>
                    </div>
                </div>

                <!-- bottom block -->
                <div class="bottom">
                    <div class="bottom__rows">
                        <div class="bottom__title">
                            Rows per page:
                        </div>
                        <div class="bottom__count">
                            <span class="bottom__row">{{ rowsCount }}</span> <span>{{ startIndex + 1 }}-{{ checkEnding }}</span> of <span>{{ selectedCards.length }}</span>
                        </div>
                    </div>
                    <div class="bottom__block">
                        <div
                            class="bottom__left"
                            :class="{active: page > 1}"
                            @click="page = page - 1">
                            <img
                                :src="require('@/assets/icons/arrow-left.svg')"
                                alt="prev">
                        </div>
                        <div
                            class="bottom__right"
                            :class="{active: hasNextPage}"
                            @click="page = page + 1">
                            <img
                                :src="require('@/assets/icons/arrow-left.svg')"
                                alt="next">
                        </div>
                    </div>
                </div>
                <!-- end bottom block -->
            </div>
        </div>
    </div>
</template>

<script>
import CreativeAdCard from '@/components/library/CreativeAdCard';
import { mapState } from 'vuex';

export default {
    name: 'LibraryMain',
    title: 'Library Main',
    components: {
        CreativeAdCard
    },
    data() {
        return {
            filter: '',
            filterOptions: [],
            selectedNumber: '3',
            sortingNumber: null,
            page: 1,
            topSelection: null,
            sortType: '',
            noMatch: false,
            cards: [
                {
                    name: 'Ford Bronco Sport Lightning',
                    platforms: ['facebook'],
                    type: 'marketplace',
                    image: 'https://i.postimg.cc/m2rZTCHS/image13.jpg',
                    author: 'Nathan Pyatte',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },
                {
                    name: 'Ford Focus Skyactive 2022 MD',
                    platforms: ['snapchat'],
                    type: 'marketplace',
                    image: 'https://i.postimg.cc/8zJ16GwJ/image14.jpg',
                    author: 'Anton Saienko',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },
                {
                    name: 'Hyundai Tiburon 2003 SX',
                    platforms: ['snapchat'],
                    type: 'marketplace',
                    image: 'https://i.postimg.cc/d3sskTSw-/image15.jpg',
                    author: 'BuyerBridge',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },
                {
                    name: 'Mazda CX-5 Restored New Account',
                    platforms: ['pinterest', 'facebook'],
                    type: 'agency',
                    image: 'https://i.postimg.cc/28JYmJmz/image16.jpg',
                    author: 'Nathan Pyatte',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },
                {
                    name: 'Dodge Super Charger Nitro Tool',
                    platforms: ['snapchat'],
                    type: 'agency',
                    image: 'https://i.postimg.cc/1RB5xJft/image17.jpg',
                    author: 'ByuerBridge',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },
                {
                    name: 'Sport Hatchback New Model',
                    platforms: ['snapchat'],
                    type: 'personal',
                    image: 'https://i.postimg.cc/k5WqTy07/image18.jpg',
                    author: 'Nathan Pyatte',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },
                {
                    name: 'Tesla MDX Teslatruck',
                    platforms: ['snapchat'],
                    type: 'marketplace',
                    image: 'https://i.postimg.cc/XJ33mCWd/image19.jpg',
                    author: 'BuyerBridge',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },
                {
                    name: 'Mercury Voyager New Model 2023',
                    platforms: ['tiktok'],
                    type: 'agency',
                    image: 'https://i.postimg.cc/nhDJLkcq/image20.jpg',
                    author: 'Nathan Pyatte',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },
                {
                    name: 'Random Name for Test Vehicle',
                    platforms: ['snapchat'],
                    type: 'personal',
                    image: 'https://i.postimg.cc/nLft9DCg/image21.jpg',
                    author: 'Anton Saienko',
                    created: 'Jan 19, 2021',
                    expires: 'Feb 22, 2021'
                },

            ]
        };
    },
    computed: {
        startIndex() { return (this.page - 1) * this.sortingNumber },

        endIndex() { return this.page * this.sortingNumber },

        filteredCards() { return this.selectedCards.filter(card => card.name.toLowerCase().includes(this.filter.toLowerCase()))},

        hasNextPage() { return this.filteredCards.length > this.endIndex },

        selectedCards() { return this.cards.filter(card => this.filtration(card))},

        rowsCount() { return this.sortingNumber / 3},

        paginatedCards() { return this.filteredCards.slice(this.startIndex, this.endIndex) },

        allPages() {
            if ((this.selectedCards.length / this.sortingNumber) >= 1) {
                return ((this.selectedCards.length / this.sortingNumber) + 1).toFixed(0);
            } else {
                return 1;
            }
        },

        checkEnding() {
            if (this.endIndex > this.selectedCards.length) {
                return this.selectedCards.length;
            } else{
                return this.endIndex;
            }
        },

        ...mapState({
            currentAgency: (state) => state.agency.currentAgency
        }),
    },
    watch: {
        filter() {
            this.page = 1;
        },
        paginatedCards() {
            if (this.filteredCards.length === 0) {
                this.noMatch = true;
            } else {
                this.noMatch = false;
            }
        }
    },
    mounted() {
        //this.filterOptions.push('first', 'facebook', 'second', 'instagram');
        console.log(this.$route.query);
        this.topSelection = 'creative_ads';
        this.sortingNumber = 3;
    },
    methods: {
        changeNumber(selectedNumber) {
            this.page = 1;
            this.sortingNumber = selectedNumber;
        },
        filtration(card) {
            let hasPlatforms;
            card.platforms.forEach(name => {
                if(this.filterOptions.includes(name)) {
                    hasPlatforms = name;
                }
            });
            if (this.filterOptions.length === 0) {
                return true;
            }
            if (this.filterOptions.includes(hasPlatforms)) {
                return true;
            }
            if (this.filterOptions.includes(card.name)) {
                return true;
            }
            if (this.filterOptions.includes(card.type)) {
                return true;
            }
        },
        sortBy(sortType) {
            if (sortType === 'ASC') {
                this.cards.sort((a, b) => (a.name > b.name) ? 1 : -1);
            }
            if (sortType === 'DESC') {
                this.cards.sort((a, b) => (b.name > a.name) ? 1 : -1);
            }
        },
        addToFilter(val) {
            if (this.filterOptions.includes(val)) {
                const index = this.filterOptions.indexOf(val);
                this.filterOptions.splice(index, 1);
            } else {
                this.filterOptions.push(val);
            }
        },
        toggleClass(event) {
            event.target.classList.toggle('checked');
        }
    }
};
</script>


<style lang="scss" scoped>
.wrapper{
    display: flex;
    margin-top:60px;
}

//filtration
.filtration{
    width: 213px;
    background: #E4EAED;
    padding: 15px 20px 30px 28px;
    box-sizing: border-box;
    position: fixed;
    height: 100vh;
    overflow: auto;
    padding-bottom: 100px;
}
.filtration__title{
    font-size: 12px;
    font-weight: 700;
    color: #00A2EA;
    margin-bottom:3px;
}
.filtration__list{
    padding:0;
    list-style-type: none;
    li{
        padding-left:25px;
        font-size: 11px;
        line-height: 16px;
        box-sizing: border-box;
        position: relative;
        margin: 7px 0;
        max-width:120px;
        cursor: pointer;
        &:before{
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            left:0;
            top: calc(50% - 9px);
            background-image: url('/img/unchecked.svg');
        }
        &.checked{
            &:before{
                background-image: url('/img/checked.svg');
            }
        }
        &:hover{
            text-decoration: underline;
        }
    }
}
.filtration__block{
    margin-bottom: 20px;;
}
.filtration__see{
    font-size: 11px;
    line-height: 16px;
    padding-left:28px;
    color: #00A2EA;
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    &:before{
        content: '';
        position: absolute;
        width: 15px;
        height: 10px;
        left:3px;
        top: calc(50% - 5px);
        background-image: url('/img/arrow.svg');
    }
    &:hover{
        text-decoration: underline;
    }
}

//basic styles
.main{
    width: calc(100% - 213px);
    margin-left: 213px;
    background: #F7F8FC;
}
.nomatch{
    padding: 20px 35px;
}
#creative_ads_label{
    border-right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
#playbooks_label{
    border-left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.select{
    background: white;
    width: 56px;
    height: 32px;
    border: 1px solid #D9DADA;
    box-sizing: border-box;
    padding-left:10px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
}
.select-wrapper{
    position: relative;
    display: inline-block;
    &:before{
        content: '';
        position: absolute;
        width: 12px;
        height: 5px;
        right:8px;
        top: calc(50% - 1px);
        background-image: url('/img/select.svg');
        pointer-events: none;
    }
}

//TOP block
.top{
    background: #ECF2F5;
    padding: 20px 35px;
}
.top__list{
    @extend .filtration__list;
    li{
        display: inline-block;
        margin-top: 20px;
        margin-right: 12px;
        padding-right: 12px;
        max-width: 100%;
        &:after{
            content: '';
            position: absolute;
            right: 0;
            top:-7px;
            width: 1px;
            height: 30px;
            background: #D9DADA;
        }
    }
}
.top__selection{
    height: 37px;
    display: inline-flex;
    input{
        display: none;
    }
    input.selected + label{
        background:#00A2EA;
        color: white;
        font-weight: 700;
        border: 0;
    }
    label{
        border: 1px solid #D9DADA;
        background:white;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 40px;
        cursor: pointer;
    }
}
.top__line{
    display: flex;
    justify-content: space-between;
}
.top__perpage{
    display: flex;
    align-items: center;

}
.top__title{
    font-size: 13px;
    font-weight: 700;
    color: #6D797F;
    margin-right: 5px;
}
.top__right{
    display: flex;
    align-items: center;
}
.top__count{
    color: #6D797F;
    font-size: 13px;
    padding-right: 13px;
    position: relative;
    margin-left:23px;
    margin-top:2px;
    &:after{
        content: '';
        position: absolute;
        right: 0;
        top:-8px;
        width: 1px;
        height: 35px;
        background: #D9DADA;
    }
}
.top__sortby{
    @extend .select;
    width: 168px;
    margin-left:20px;
}

//cards
.cards{
    padding: 20px 35px;
}
.subhead{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.subhead__searchwrap{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left:18px;
        top: calc(50% - 9px);
        background-image: url('/img/search.svg');
        pointer-events: none;
    }
}
.subhead__search{
    background: white;
    width: 503px;
    height: 40px;
    border: 1px solid #D9DADA;
    box-sizing: border-box;
    padding-left:10px;
    font-size: 16px;
    outline: none;
    border-radius: 5px;
    padding-left:55px;
}
.subhead__create{
    a{
        text-decoration: none;
        background: #7ED321;
        color: white;
        border-radius: 5px;
        display:block;
        padding: 17px 0;
        width: 201px;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight:600;
    }
}

//card
.card-wrapper{
    width: calc(100% + 64px);
    margin-left: -32px;
}

//bottom
.bottom{
    height: 60px;
    border-top: 1px solid #D9DADA;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 0 90px;
}
.bottom__left{
    padding: 10px;
    opacity: 0.5;
    pointer-events: none;
    margin-right: 35px;
    img{
        transform: rotate(180deg);
    }
    &.active{
        opacity: 1;
        cursor: pointer;
        pointer-events: all;
    }
}
.bottom__right{
    padding: 10px;
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none;
    &.active{
        opacity: 1;
        cursor: pointer;
        pointer-events: all;
    }
}
.bottom__block{
    display: flex;
    align-items: center;
}
.bottom__rows{
    display: flex;
    align-items: center;

}
.bottom__title{
    font-size: 13px;
    font-weight: 700;
    color: #6D797F;
    margin-right: 5px;
}
.bottom__count{
    color: #6D797F;
    font-size: 13px;
    padding-right: 46px;
    position: relative;
    margin-left:45px;
    margin-top:2px;
}
.bottom__row{
    margin-right:65px;
}
</style>
